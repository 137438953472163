<template>
    <div class="reset-account-index-page">
        <h1 class="reset-account-index-page__title">{{ $lang.pages.olvideMiContrasena.forgot_password }}</h1>
        <AuthDialogForgotPassword class="reset-account-index-page__form" :page="true" />
    </div>
</template>

<script lang="ts" setup>
const { $lang } = useNuxtApp()

const title = $lang.pages.olvideMiContrasena.title_seo
const description = $lang.pages.olvideMiContrasena.description_seo

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

definePageMeta({
    middleware: ['not-auth-required'],
})

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.reset-account-index-page {
    @apply container pb-5 lg:pb-10;

    &__title {
        @apply mb-10 mt-5 text-center text-4xl lg:mt-10;
    }

    &__subtitle {
        @apply mb-3 text-center text-gray-800;
        a {
            @apply cursor-pointer text-site-primary hover:underline;
        }
    }
    &__form {
        @apply mx-auto max-w-[800px] overflow-hidden rounded-lg;
    }
}
</style>
